import {useCallback, useContext} from "react";
import {GeneralContext} from "../../../providers/GeneralProvider";
import {RosteringContext} from "./RosteringContextProvider";
import {isEmployeeLateWorker} from "./StaffListProvider";

export const useWorkLimitFilter = () => {
    const lang = useContext(GeneralContext);
    const isTemplate = useContext(RosteringContext)?.isTemplate;
    return useCallback((
        employee: any,
        getMaxAvailableShiftLength: (employee: any, endTime: number) => number | undefined,
        startTime?: number,
        endTime?: number
    ) => {
        if (isTemplate) {
            return true;
        }

        if (!employee.availability) {
            return false;
        }

        const shiftLength = startTime && endTime ? endTime - startTime : 0;

        const maxShiftLength = getMaxAvailableShiftLength(employee, endTime ?? 0);
        if (maxShiftLength !== undefined && shiftLength > maxShiftLength) {
            return false;
        }

        const employeeContract = lang.contractTypes?.find(contract => contract.id === employee.contractTypeId);

        /* This code was disabled after we find it difficult to edit double-shifts. Rules can be broken while editing

        if (rosteringContext && isEmployeeHighschooler(employee)) {
            let assignedNetShiftLength = 0;
            const myPositions = rosteringContext.getMyPositions(employee.identityId);
            myPositions?.forEach(p => {
                let breakLength = 0;
                p.breaks?.forEach((brek) => breakLength += (brek.end - brek.start));
                assignedNetShiftLength += (p.end - p.start - breakLength)
            });
            if (shiftLength + assignedNetShiftLength > employeeContract.maxHoursPerDay * 3600) {
                return false;
            }
        }
         */

        if (endTime && !isEmployeeLateWorker(employee) && endTime > employeeContract.latestEndTime) {
            return false;
        }

        return !(startTime && endTime) ||
          !(employee.availability.start > startTime || employee.availability.end < endTime);
    }, [isTemplate, lang.contractTypes]);
}