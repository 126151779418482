import {
    EditAddRemovePositionsOptions,
    EditPositionEmployeeOptions,
    EditPositionTimeSlotOptions,
    EditSelectTasksOptions,
    EditStationTasksOptions,
    EditStationTaskTimeSlotOptions,
    EditRemoveEmployeesFromStation,
    EditBreaksOptions,
    SetSectionLeaderOptions, EditManagementStationOptions
} from "./gql/GanttGqlInterfaces";
import React, {useCallback, useContext} from "react";
import {GeneralContext} from "../../../providers/GeneralProvider";

interface RosteringContextInterface {
    updated: (data: any | undefined, fullUpdate?: boolean) => void;
    positionAdded: (stationId: number, newPosition: any) => void;
    positionTimeSlotUpdated: (data: any) => void;
    positionPropertiesUpdated: (positionId, properties) => void;
    breaksUpdated: (positionId: string, data: any) => void;
    afterHoursUpdated: (newValue: number) => void;
    sectionLeaderUpdated: (positionId: string, newValue: boolean) => void;
    employeeUpdated: (positionId: string, employee?: object) => void;
    employeesUpdated: (positionIds: string[], employee?: object) => void;
    positionRemoved: (positionId: string) => void;
    selectTasksOptions?: EditSelectTasksOptions;
    addRemovePositionsOptions?: EditAddRemovePositionsOptions;
    removeEmployeesFromPosition?: EditRemoveEmployeesFromStation;
    setPositionTimeSlotOptions?: EditPositionTimeSlotOptions;
    addRemoveEmployeeToPositionOptions?: EditPositionEmployeeOptions;
    editManagementStationOptions?: EditManagementStationOptions;
    addRemoveUpdateStationTaskTimeSlotOptions?: EditStationTaskTimeSlotOptions;
    selectStationTaskOptions?: EditStationTasksOptions;
    editBreaksOptions?: EditBreaksOptions;
    setSectionLeaderOptions?: SetSectionLeaderOptions;
    ganttSlots: Date[];
    data: any; // roster or template data
    tasks?: any[];
    hqTarget?: number;
    operatingHours: any;
    id: string;
    printMode?: boolean;
    baseTemplate?: any;
    isTemplate?: boolean;
    afterHours?: number;
    getMyPositions: (identityId: string) => any[];
    schoolHolidays?: any[];
    calculateMaxHoursPerWeekForForeignStudent?: (employee: any, firstDayOfWeek: Date) => number | undefined;
    getMaxShiftLengthForForeignStudent?: (employee: any, date: Date) => number | undefined;

    updateAfterHours?: (newValue: number) => void;
    // apply afterHours to shift end time based on contract, operating hours...
    applyAfterHours: (shiftEnd: number, employee?: any[], forBreakCalculation?: boolean) => number;

    // Extend slots to cover "afterHours"
    extendSlots: (slots: any[]) => any[];

    // count the number of "afterHours"
    countAfterHoursSlots: (slotSize: number) => number;
}

export const RosteringContext = React.createContext<RosteringContextInterface | null>(
    null
);

export const RosteringContextProvider = (props) => {
    const generalContext = useContext(GeneralContext);

    const getAfterHours = useCallback(() => {
        return props.data?.afterHours ?? 0;
    }, [props.data]);

    const applyAfterHours = useCallback((shiftEnd, employee, forBreakCalculation) => {
        const afterHours = getAfterHours();
        if (afterHours > 0 && employee?.contract?.lateWorker && props.operatingHours && shiftEnd >= props.operatingHours.closeTime) {
            // for break calculation, the value of after hours is always 60 minutes.
            return props.operatingHours.closeTime + (forBreakCalculation === true ? 60 * 60 : afterHours);
        }
        return shiftEnd;
    }, [getAfterHours, props.operatingHours]);

    const extendSlots = useCallback((slots: {start: number, end: number}[]) => {
        const afterHours = getAfterHours();
        if (afterHours > 0 && slots.length) {
            const closeTime = slots[slots.length - 1].end;
            const extraSlots = Math.floor(afterHours / (15 * 60));

            const extendedSlots = [...slots];
            for (let count = 0; count < extraSlots; count++) {
                const slotStart = closeTime + count * 15 * 60;
                extendedSlots.push({start: slotStart, end: slotStart + 15 * 60});
            }
            return extendedSlots;
        }
        return slots;
    }, [getAfterHours]);

    const countAfterHoursSlots = useCallback((slotSize: number) => {
        const afterHours = getAfterHours();
        return afterHours > 0 ? Math.floor(afterHours / (slotSize * 60)) : 0;
    }, [getAfterHours]);

    const calculateMaxHoursPerWeekForForeignStudent = useCallback((employee: any, firstDayOfWeek: Date) => {
        return generalContext.calculateMaxHoursPerWeekForForeignStudent(employee, firstDayOfWeek, props.schoolHolidays);
    }, [generalContext.calculateMaxHoursPerWeekForForeignStudent, props.schoolHolidays]);

    const getMaxShiftLengthForForeignStudent = useCallback((employee: any, date: Date) => {
        return generalContext.getMaxShiftLengthForForeignStudent(employee, date, props.schoolHolidays);
    }, [generalContext.getMaxShiftLengthForForeignStudent, props.schoolHolidays]);

    const refRosteringContextContainer = {
        updated: props.updated,
        positionAdded: props.positionAdded,
        positionTimeSlotUpdated: props.positionTimeSlotUpdated,
        positionPropertiesUpdated: props.positionPropertiesUpdated,
        breaksUpdated: props.breaksUpdated,
        afterHoursUpdated: props.afterHoursUpdated,
        sectionLeaderUpdated: props.sectionLeaderUpdated,
        employeeUpdated: props.employeeUpdated,
        employeesUpdated: props.employeesUpdated,
        positionRemoved: props.positionRemoved,
        selectTasksOptions: props.selectTasksOptions,
        addRemovePositionsOptions: props.addRemovePositionsOptions,
        removeEmployeesFromPosition: props.removeEmployeesFromPosition,
        setPositionTimeSlotOptions: props.setPositionTimeSlotOptions,
        addRemoveEmployeeToPositionOptions: props.addRemoveEmployeeToPositionOptions,
        editManagementStationOptions: props.editManagementStationOptions,
        addRemoveUpdateStationTaskTimeSlotOptions: props.addRemoveUpdateStationTaskTimeSlotOptions,
        selectStationTaskOptions: props.selectStationTaskOptions,
        editBreaksOptions: props.editBreaksOptions,
        setSectionLeaderOptions: props.setSectionLeaderOptions,
        ganttSlots: props.ganttSlots,
        operatingHours: props.operatingHours,
        id: props.id,
        data: props.data,
        afterHours: props.isTemplate ? 0 : getAfterHours(),
        tasks: props.tasks,
        hqTarget: props.hqTarget,
        printMode: props.printMode,
        baseTemplate: props.baseTemplate,
        isTemplate: props.isTemplate,
        getMyPositions: props.getMyPositions,
        updateAfterHours: props.updateAfterHours,
        applyAfterHours: applyAfterHours,
        extendSlots: extendSlots,
        countAfterHoursSlots: countAfterHoursSlots,
        schoolHolidays: props.schoolHolidays,
        calculateMaxHoursPerWeekForForeignStudent: calculateMaxHoursPerWeekForForeignStudent,
        getMaxShiftLengthForForeignStudent: getMaxShiftLengthForForeignStudent
    };

    return (
        <RosteringContext.Provider value={refRosteringContextContainer}>
            {props.children}
        </RosteringContext.Provider>
    )
}